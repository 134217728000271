<template>
  <div class="container-sm mt-5">
    <div class="row">
      <div class="col-12 col-md-10 col-lg-8 mx-auto">
        <div class="row mb-4">
          <span class="col-sm-10 offset-sm-2 font-monospace fw-bold text-uppercase text-center">
            {{ expires.local().format("llll") }} ({{ expires.fromNow() }})
          </span>
        </div>
        <div class="row mb-4">
          <label class="col-sm-2 col-form-label">Interval Type</label>
          <div class="col-sm-10">
            <select
              :class="['form-control', {'is-invalid': v$.interval.unit.$errors.length}]"
              v-model="interval.unit"
              @input="v$.interval.unit.$touch()"
            >
              <option v-for="unit in Object.keys(intervalUnit)" :key="unit" :value="unit">
                {{ intervalUnit[unit] }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mb-4">
          <label class="col-sm-2 col-form-label">Interval Count</label>
          <div class="col-sm-10">
            <input
              :class="['form-control', {'is-invalid': v$.interval.count.$errors.length}]"
              v-model="interval.count"
              type="number"
              @input="v$.interval.count.$touch()"
            />
            <div class="invalid-feedback">
              <small v-if="v$.interval.count.required.$invalid">Interval count wajib diisi</small>
              <small v-else-if="v$.interval.count.numeric.$invalid">Interval count tidak valid</small>
              <small v-else-if="v$.interval.count.valid.$invalid">Interval count minimal 1</small>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-sm-10 offset-sm-2">
            <div class="row">
              <div class="col-6">
                <div
                  @click="licenseType = 'token'"
                  :class="[{'bg-primary text-white': licenseType == 'token'}, 'p-2 border d-flex justify-content-center align-items-center font-monospace']"
                  style="border-radius: 0.25rem"
                >
                  TOKEN
                </div>
              </div>
              <div class="col-6">
                <div
                  @click="licenseType = 'file'"
                  :class="[{'bg-primary text-white': licenseType == 'file'}, 'p-2 border d-flex justify-content-center align-items-center font-monospace']"
                  style="border-radius: 0.25rem"
                >
                  FILE
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10 offset-sm-2">
            <button type="button" class="btn btn-primary w-100" @click="done">
              Hasilkan Lisensi
            </button>
          </div>
        </div>
        <div class="row mt-4" v-if="licenseKey">
          <div class="col-sm-10 offset-sm-2">
            <div class="p-3 border d-flex align-items-center justify-content-center" style="border-radius: 0.25rem">
              <h1 class="font-monospace text-success">{{ licenseKey }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import utils from "./utils";
import moment from "moment";
import Vuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import CryptoJS from "crypto-js";

const AES_KEY = "nZr4t7w!z%C*F-JaNdRgUkXp2s5v8x/A?D(G+KbPeShVmYq3t6w9z$B&E)H@McQf",
      AES_IV  = "28472B4B6250655368566D5971337436";

export default {
  setup() {
    return { v$: Vuelidate() }
  },
  data() {
    return {
      licenseKey: null,
      licenseType: "token",
      intervalUnit: {
        minutes: "Menit",
        hours: "Jam",
        days: "Hari",
        weeks: "Minggu",
        months: "Bulan",
        years: "Tahun"
      },
      interval: {
        unit: "days",
        count: "1"
      }
    }
  },
  validations() {
    return {
      interval: {
        unit: {
          valid: value => Object.keys(this.intervalUnit).includes(value)
        },
        count: {
          required,
          numeric,
          valid: value => (value >= 1)
        }
      }
    }
  },
  computed: {
    expires() {
      return moment.utc().add(this.interval.count, this.interval.unit);
    }
  },
  methods: {
    encrypt(string) {
      const key = CryptoJS.enc.Base64.parse(AES_KEY);
      const iv = CryptoJS.enc.Base64.parse(AES_IV);
      
      return CryptoJS.AES.encrypt(string, key, { iv }).toString();
    },
    encryptToken(string) {
      let result = "";

      for (let i in string) {
        if (i % 2 == 0) {
          result+= utils.randomString(1).toUpperCase() + string[i]
        } else {
          result+= string[i]
        }
      }

      return result;
    },
    async done() {
      this.v$.$validate();

      if (this.v$.$error) {
        return;
      }

      this.licenseKey = null;

      const id = utils.randomString(50);

      const obj = {
        id: id,
        expires_at: this.expires.toISOString(),
        created_at: moment.utc().toISOString()
      }

      const encrypted = this.encrypt(JSON.stringify(obj))
      const separed = [];

      for (let chr of encrypted) {
        separed.push(chr.charCodeAt(0));
      }

      const license = separed.reverse().join("oOo");

      if (this.licenseType == "file") {
        const blob = new Blob([license], {type: "text/plain"});
        const el = document.createElement("a");

        el.setAttribute("download", `${this.expires.toString()}.license`);
        el.setAttribute("style", "display:none");
        el.setAttribute("href", window.URL.createObjectURL(blob));

        document.body.appendChild(el);

        el.click();

        document.body.removeChild(el);
        return;
      }

      try {
        const params = new URLSearchParams({
          key: license,
          created: obj.created_at
        }).toString();

        const response = await fetch(`https://tinyurl.com/api-create.php?url=${encodeURIComponent('https://graph.facebook.com/?' + params)}`);
        const url = this.parseURL(await response.text());

        if (!url) {
          return alert("Tidak dapat membuat lisensi!");
        }

        const key = url.pathname?.match(/\/([a-zA-Z0-9]+)/);

        if (!key) {
          return alert("Tidak dapat membuat lisensi!");
        }

        this.licenseKey = this.encryptToken(key[1]);
      } catch (e) {
        alert(e?.toString());
      }
    },
    parseURL(url) {
      try {
        return new URL(url);
      } catch (e) {
        return null;
      }
    }
  }
}
</script>
