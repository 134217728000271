import { createApp } from 'vue';
import App from './App.vue';
import moment from "moment";
import VueCryptojs from 'vue-cryptojs'

import "bootstrap/scss/bootstrap.scss";

moment.locale("id");

createApp(App).use(VueCryptojs).mount('#app');
